$('body').on('submit', 'form', function (e) {
	let callback_functions = {
		showScratchcard: window.showScratchcard,
		disableInputs: window.disableInputs,
	}

	if ($(this).hasClass('ajax-form')) {
		e.preventDefault();

		let self = this;

		$('.form-control').removeClass('is-invalid');
		$('.invalid-feedback').removeClass('d-block').removeClass('text-dark');

		let action = $(self).attr('action');
		let formData = new FormData(this);
		let method = $(self).attr('method');
		if (!method) {
			method = "post";
		}

		let success_callback = function (result) {
			if (result.hasOwnProperty('redirect')) {
				document.location.href = result.redirect;
			}
		}

		let send_callback = function(form) {};

		if($(self).attr('data-success-callback')) {
			success_callback = callback_functions[$(self).attr('data-success-callback')];
		}
		if($(self).attr('data-send-callback')) {
			send_callback = callback_functions[$(self).attr('data-send-callback')];
		}

		$.ajax({
			url: action,
			method: method,
			data: formData,
			cache: false,
			contentType: false,
			processData: false,
			beforeSend: function () {
				send_callback($(self));
			},
			error: function (result) {
				let data = result.responseJSON;
				let errors = data.errors;

				if (!jQuery.isEmptyObject(errors)) {
					for (let field in errors) {
						if (!errors.hasOwnProperty(field)) continue;
						let message = errors[field][0];
						let inputField = $('#'+field);

						inputField.closest('.control').parent().find('.invalid-feedback').addClass('d-block');
						inputField.closest('.control').parent().find('.invalid-feedback').html(message);
					}

				}
			},
			success: function (result) {
				success_callback(result);
			},
		});
	}
});
