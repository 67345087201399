import {ScratchCard, SCRATCH_TYPE} from "scratchcard-js/build/scratchcard.min";

const scContainer = document.getElementById('js--sc--container');

if(scContainer) {
	const sc = new ScratchCard('#js--sc--container', {
		scratchType: SCRATCH_TYPE.CIRCLE,
		containerWidth: scContainer.offsetWidth,
		containerHeight: scContainer.offsetHeight,
		imageForwardSrc: 'img/scratchcard.png',
		imageBackgroundSrc: '',
		clearZoneRadius: 35,
		nPoints: 0,
		pointSize: 0,
		callback: function () {
			return null;
		}
	});

	// Init
	sc.init().then(() => {
		$('#scratchcard').addClass('d-none');
	}).catch((error) => {
		// image not loaded
		console.log(error.message);
	});
}