$(".navbar-burger").click(function () {
	$(".navbar-burger").toggleClass("is-active");
	$(".navbar-menu").toggleClass("is-active");
});

$('#retailer').on('change', function () {
	if ($(this).val() === 'carrefour') {
		$('#retailer_carrefour_notice').addClass('d-block');
	} else {
		$('#retailer_carrefour_notice').removeClass('d-block');
	}
});

$('#file').on('change', function () {
	var filename = $(this).val().split(/(\\|\/)/g).pop();
	$('#file-label').text(filename);
});

window.showScratchcard = function (_) {
	$('#scratchcard').removeClass('d-none');
	document.getElementById('scratchcard').scrollIntoView();
}
window.disableInputs = function (form) {
	form.find('.input').prop('disabled', true);
	form.find('#btn-submit').prop('disabled', true);
}